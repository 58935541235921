import React from 'react'
import ProfileIntroSection from '../ProfileIntroSection'
import ProfileListItem from '../ProfileListItem'
import EventListItem from '../EventListItem'
import Head from '../Head'
import ProfileList from '../ProfileList'
import { META } from '../../utils/constants'
import { ContentWrapper } from '../../style/shared'
import { ListsSection, ClientsList } from './style'

export default ({ data }) => {
  const { clients, events, mentions, articles } = data
  
  return (
      <ContentWrapper>
        <Head 
          {...META.profile}
          image={META.common.image}
        />
        <ProfileIntroSection 
          content={() =>
            <>            
              <p> Over the last 4 years, I have been a Computer Science student at the University of Waterloo. I have worked with small and big companies in North America. 
                 I focus on all domains of programming and have experience in building, prototyping, implementing user interfaces that are usable and scalable for iOS apps and web-based applications.{' '}
             I enjoy experimenting, <a href="https://github.com/commai" target="_blank" rel="noopener noreferrer">building and trying</a> out new tools.</p>
              <p>Sometimes <a href="https://twitter.com/VardanSawhney" target="_blank" rel="noopener noreferrer">I tweet</a> and blog on <a href="https://medium.com/@vardan_sawhney" target="_blank" rel="noopener noreferrer">Medium</a>.</p>
		  <p> Currently looking for Fall 2022 internship opportunities. If you like what you see, feel free to contact me at vsawhney@uwaterloo.ca </p>
      <p>If you would like more information about me, check out my <a href="https://drive.google.com/file/d/1bRo8a4RRbyqeCGff-RaI3OJ1IAsEGrlh/view?usp=sharing" target="_blank" rel="noopener noreferrer">résumé</a> or scroll down!</p>
            </>
          }
        />
        <ListsSection>
          {events.edges.length > 0 && 
            <ProfileList 
              title='Timeline'
              list={() => events.edges.map(({ event }, i) => (
                <EventListItem 
                  key={i}
                  {...event}
                />
              ))}
            />}
          {articles.edges.length > 0 && 
            <ProfileList 
              title='Articles'
              list={() => articles.edges.map(({ article }, i) => (
                <ProfileListItem 
                  key={i}
                  {...article}
                />
              ))}
            />}
          {mentions.edges.length > 0 && 
            <ProfileList 
              title='Awards'
              list={() => mentions.edges.map(({ mention }, i) => (
                <ProfileListItem 
                  key={i}
                  {...mention}
                />
              ))}
            />}
        </ListsSection>
        </ContentWrapper>
  )
}
